import { useMemo } from "react"

interface IUseStrapiShopProductHook {
  product: Strapi.ShopProduct
}

export const findPriceForCurrency = (
  sku: Strapi.ShopProductSKU,
  currency: string
) => {
  return sku.pricings.find(
    d => d.currency.toLocaleLowerCase() === currency.toLocaleLowerCase()
  )
}

const filterProduct = (
  product: Strapi.ShopProduct,
  country?: Strapi.Country
) => ({
  ...product,
  skus: product.skus
    .map(sku => {
      return {
        ...sku,
        pricings: sku.pricings.filter(pricing => {
          return (
            country &&
            pricing.countries.map(c => c.locale).indexOf(country.locale) >= 0
          )
        }),
      }
    })
    .filter(sku => sku.pricings.length > 0),
})

export const formatProductForCountry = (
  product: Strapi.ShopProduct,
  country?: Strapi.Country
): Strapi.ShopProduct => {
  if (
    product.digital &&
    country &&
    country.currencyCode &&
    country.conversionRateToUSD
  ) {
    // Add pricing for country
    // @ts-ignore
    const skus: Strapi.ShopProductSKU[] = product.skus
      .map(sku => {
        let usdPrice
        let regularPrice
        if (country && country.currencyCode) {
          regularPrice = findPriceForCurrency(sku, country.currencyCode)
        }
        if (sku.sku !== "escape-from-conform-prison-gift-voucher") {
          usdPrice = findPriceForCurrency(sku, "USD")
        }
        if (!usdPrice && !regularPrice) return null
        return {
          ...sku,
          pricings: [
            {
              amount:
                regularPrice?.amount ||
                Math.round(
                  (country.conversionRateToUSD as number) * usdPrice.amount
                ),
              currency: country.currencyCode as string,
              countries: [country],
            },
          ],
        }
      })
      .filter(d => d && d?.pricings.length > 0)
    return { ...product, skus }
  }

  return filterProduct(product, country)
}

const useStrapiShopProduct = (
  inputProduct: Strapi.ShopProduct,
  country: Strapi.Country
): IUseStrapiShopProductHook => {
  const product = useMemo(() => {
    const formatted = formatProductForCountry(inputProduct, country)

    return {
      ...formatted,
    }
  }, [inputProduct])

  return {
    product,
  }
}

export default useStrapiShopProduct
