import styled from "styled-components"

export const Wrapper = styled.div`
  color: #662d91;
  padding-top: 50px;
`

export const MobileContent = styled.div`
  display: none;
  @media (max-width: 860px) {
    display: block;
  }
  padding: 0 20px;
`

export const Content = styled.div`
  padding: 30px 20px 30px 20px;
  max-width: 1100px;
  margin: 0 auto;
  margin-bottom: 50px;
`

export const Title = styled.div`
  font-size: 38px;
  text-align: center;
  margin-bottom: 40px;
`

export const Table = styled.table`
  width: 100%;
  thead {
    padding-bottom: 50px;
  }
  @media (max-width: 860px) {
    display: none;
  }
`

export const MobileItem = styled.div`
  margin-bottom: 25px;
  text-align: center;
  > :first-child {
    width: 100%;
  }
  .product--title {
  }
  .product--image {
    width: 250px !important;
    height: 250px !important;
  }
  > * {
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const Product = styled.div`
  display: flex;
  align-items: center;
  .product--image {
    position: relative;
    height: 140px;
    width: 140px;
    background-color: rgb(235, 235, 235);
    border-radius: 12px;
    margin-right: 15px;
    img {
      position: absolute;
      object-fit: contain;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .product--actions {
    display: flex;
    @media (max-width: 860px) {
      justify-content: center;
    }
    > * {
      margin-right: 10px;
    }
    > :last-child {
      margin-right: 0px;
    }
  }
  .product--title {
    font-weight: bold;
    > :first-child {
      margin-bottom: 12px;
    }

    > .product-info {
      font-weight: regular;
      font-size: 11px;
      margin-top: -10px;
      margin-bottom: 12px;
    }
  }
`

export const MobileProductContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    margin-bottom: 12px;
  }
  margin-top: 12px;
  width: 100%;
  max-width: 400px;
`

export const Price = styled.div``
export const Quantity = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: 10px;
  }
  > :last-child {
    margin-right: 0;
  }
  .quantity--control {
    > :first-child {
      margin-right: 10px;
    }
  }
`
export const Total = styled.div``

export const SubTotal = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  margin-bottom: 40px;
  padding-right: 40px;
  > :first-child {
    margin-right: 40px;
  }
`

export const ShippingCosts = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
  padding-right: 40px;
  margin-top: -30px;
  > :first-child {
    margin-right: 40px;
  }
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 20px 20px 20px;
  > * {
    margin-right: 30px;
  }
  > :last-child {
    margin-right: 0;
  }
  @media (max-width: 463px) {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    > * {
      margin-right: 0px;
      margin-bottom: 20px;
    }
    > :last-child {
      margin-bottom: 0;
    }
  }
`

export const Info = styled.p`
  font-size: 12px;
  line-height: 16px;
  margin-top: 20px;
  a {
    text-decoration: underline;
  }
`

export const OtherPaymentOptions = styled.div`
  display: flex;
  align-items: center;
  > :first-child {
    margin-right: 12px;
    font-size: 18px;
    font-weight: bold;
  }
`

export const ModalOverlay = styled.div`
  position: fixed;
  background-color: rgba(0,0,0,0.6);
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  right:0;
  display: flex;
  align-items: center;
  justify-content: center;
`