import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Recommendation from './Recommendation'

interface IProps {
  sku: string
  recommendSku: string
}

const BookActivitiesRecommendation = (props: IProps) => {
  const { sku, recommendSku } = props

  const { strapiShopProducts } = useStaticQuery(graphql`
    {
      strapiShopProducts(
        slug: { eq: "reading-comprehension-companion-pack" }
      ) {
        id
        slug
        name
        thumbnail {
          url
        }
        digital
        skus {
          id
          sku
          title
          warehouse
          thumbnail {
            url
          }
          pricings {
            slug
            shippingRate
            shopShippingRate {
              id
              country {
                id
                locale
              }
            }
            countries {
              currencyCode
              code
              locale
              conversionRateToUSD
            }
            currency
            amount
          }
        }
      }
    }
  `)

  return <Recommendation sku={sku} recommendSku={recommendSku} product={strapiShopProducts} reason='247 reading comprehension printable activities, the perfect companion for the Night Zookeeper Adventure Book Series' />
}

export default BookActivitiesRecommendation
