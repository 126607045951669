import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
// @ts-ignore
import { useShoppingCart } from 'use-shopping-cart'
import BookActivitiesRecommendation from './BookActivitiesRecommendation'

interface IProps {
  recommendations: {
    sku: string
    recommendSku: string
  }[],
}

const Wrapper = styled.div`
  margin-bottom: 20px;
`

const Title = styled.div`
  margin-bottom: 10px;
  font-size: 20px;
  font-family: 'Rammetto One';
  text-align: center;
`

const List = styled.div`
  > * {
    margin: 0 auto;
    max-width: 600px;
  }
`

const Recommendations = (props: IProps) => {
  const { recommendations } = props
  const {
    cartDetails,
  } = useShoppingCart()
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const v = recommendations.find(({ sku, recommendSku }) => {
      return cartDetails[sku] && !cartDetails[recommendSku]
    })
    setVisible(Boolean(v))
  }, [recommendations])

  if (!visible) return null
  return <Wrapper>
    <Title>We recommend</Title>
    <List>
    {
      recommendations.map(({ sku, recommendSku }) => {
        if (['NZK-COMPLETE-BUNDLE', 'books-cards-pack'].indexOf(sku) >= 0) {
          return <BookActivitiesRecommendation key={sku} sku={sku} recommendSku={recommendSku} />
        }
        return null
      })
    }
    </List>
  </Wrapper>
}

export default Recommendations
