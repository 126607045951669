import { formatAmount } from 'components/Strapi/StrapiPlanHero'
import RatioBox from 'components/UI/RatioBox'
import { graphql, useStaticQuery } from 'gatsby'
import { Button } from 'nzk-react-components'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
// @ts-ignore
import { useShoppingCart } from 'use-shopping-cart'
import { formatProductForCountry } from '../../../../../hooks/useStrapiShopProduct'
import { useShopLocaleState } from '../../../../../providers/ShopLocaleProvider'

interface IProps {
  sku: string
  recommendSku: string
  product: Strapi.ShopProduct
  reason?: string
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .thumbnail {
    position: relative;
    height: 140px;
    width: 140px;
    min-width: 140px;
    background-color: rgb(235, 235, 235);
    border-radius: 12px;
    margin-right: 15px;
    img {
      position: absolute;
      object-fit: contain;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .product-info {
  }
  .product-info--title {
    font-weight: bold;
    margin-bottom: 4px;
  }
  .product-info--price {
    font-weight: bold;
    margin-bottom: 15px;
  }
  .product-info--reason {
    margin-bottom: 8px;
    font-size: 13px;
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: center;
    .thumbnail {
      width: 100%;
      max-width: 250px;
      height: 100%;
      margin-bottom: 15px;
      img {
        position: absolute;
        object-fit: contain;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
`

const Recommendation = (props: IProps) => {
  const { sku, recommendSku, product, reason } = props
  const {
    cartDetails,
    addItem
  } = useShoppingCart()
  const { locale } = useShopLocaleState()

  const { allStrapiCountries } = useStaticQuery(graphql`
    {
      allStrapiCountries {
        edges {
          node {
            locale
            name
            currencyCode
            conversionRateToUSD
          }
        }
      }
    }
  `)
  const [visible, setVisible] = useState(cartDetails[sku] && !cartDetails[recommendSku])

  useEffect(() => {
    setVisible(cartDetails[sku] && !cartDetails[recommendSku])
  }, [cartDetails])


  const { node: country } = allStrapiCountries.edges.find(
    ({ node }) => node.locale === locale
  ) as { node: Strapi.Country }
  
  const [skuObj, priceObj] = useMemo(() => {
    const p = formatProductForCountry(product, country)
    return [
      p.skus[0],
      p.skus[0]?.pricings[0]
    ]
  }, [product, country])

  const formattedAmount = formatAmount(priceObj.currency, priceObj.amount)

  const onAddItem = () => {
    addItem(
      {
        id: skuObj.sku,
        sku: skuObj.sku,
        price: priceObj?.amount,
        currency: priceObj?.currency.toUpperCase(),
        name: product.name,
        description: skuObj.shortDescription,
        image: skuObj.thumbnail?.url || product.thumbnail?.url,
        metadata: {
          warehouse: skuObj.warehouse,
          countryCodes: product.digital ? [] : priceObj?.countries?.map(c => c.code),
          shippingRate: product.digital ? undefined : priceObj?.shippingRate,
          shopShippingRate: priceObj.shopShippingRate,
          digital: product.digital,
        },
      },
      { count: 1 }
    )
  }


  if (!visible) return null
  return <Wrapper>
    <div className='thumbnail'>
      <RatioBox ratio='1:1'>
        <img src={product.thumbnail.url} alt={product.name} />  
      </RatioBox>
    </div>
    <div className='product-info'>
      <div className='product-info--title'>{product.name}</div>
      { reason && <div className='product-info--reason'>{reason}</div> }
      <div className='product-info--price'>{formattedAmount}</div>
      <div className='product-info--actions'>
        <Button theme='primary' onClick={() => onAddItem()} size='small'>Add to Basket</Button>
      </div>
    </div>
  </Wrapper>
}

Recommendation.defaultProps = {
  reason: undefined
}

export default Recommendation
