import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  animation: animate-in-overlay 0.5s;
  width: 100%;
  &.exiting {
    animation: animate-out-overlay 0.5s forwards;
    animation-delay: 0.2s;
  }
  @keyframes animate-out-overlay {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes animate-in-overlay {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  animation: animate-in 0.5s forwards;
  animation-delay: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-100vh);
  &.exiting {
    animation: animate-out 0.5s forwards;
  }
  @keyframes animate-in {
    from {
      transform: translateY(-100vh);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes animate-out {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-100vh);
    }
  }
`

const useModal = (component, onDismiss?: () => void) => {
  const [shown, setShown] = useState(false)
  const [exiting, setExiting] = useState(false)

  const open = () => {
    document.body.style.overflow = "hidden"
    setShown(true)
  }

  const dismiss = async (force?: boolean) => {
    if (!force) {
      setExiting(true)
      await new Promise(res => setTimeout(res, 500))
    }
    setShown(false)
    setExiting(false)
    document.body.style.overflow = "auto"
    if (onDismiss) {
      onDismiss()
    }
  }

  useEffect(() => {
    return () => {
      dismiss(true)
    }
  }, [])

  const Modal = useMemo(() => {
    if (!shown) return null
    return (
      <Overlay className={exiting ? "exiting" : ""} onClick={() => dismiss()}>
        <Wrapper
          className={exiting ? "exiting" : ""}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          {React.cloneElement(component, { dismiss })}
        </Wrapper>
      </Overlay>
    )
  }, [component, shown, dismiss])

  return {
    Modal,
    open,
    dismiss,
  }
}

export default useModal
