import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled, { css } from "styled-components"
import { FONT_FAMILIES } from "../../../styles/Theme"
import Button from "../Button"

const Wrapper = styled.div`
  position: relative;
  padding: 20px 15px 20px 15px;
  ${(props: { outlineColor: string }) => css`
    border: 2px solid ${props.outlineColor};
  `}
  background-color: #fff;
  border-radius: 12px;
  display: inline-flex;
  flex-direction: column;
  width: auto;
  text-align: center;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.5);
  transition: transform 0.3s;
  transform: scale(1);
  :hover {
    transform: scale(1.1);
  }
  color: #000;
`

const Title = styled.div`
  font-size: 2rem;
  ${(props: { color: string }) => css`
    color: ${props.color};
  `}
`

const Description = styled.div`
  ${(props: { color: string }) => css`
    color: ${props.color};
  `}
`

const Price = styled.div`
  font-size: 1.6rem;
  margin-bottom: 6px;
  margin-top: 12px;
`

const PriceInfo = styled.div`
  font-size: 0.8rem;
  margin-bottom: 15px;
  color: #b2b4b3;
`

const Badge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 70px;
  width: 70px;
  ${FONT_FAMILIES.rammetto};
  font-size: 0.9rem;
  line-height: 1.2;
  padding: 0 10px;
  color: #fff;
  transform: translate(50%, 20%) rotate(20deg);
  background-color: #ff4822;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid #ff7255;
  box-shadow: 0 4px 0 #bb2000, 0 8px 0 rgba(0, 0, 0, 0.3);
`

const Sam = styled.div`
  position: relative;
  height: 140px;
  width: 100%;
  margin-top: -30px;
  pointer-events: none;
  > :first-child {
    position: absolute;
    height: 145% !important;
    transform: translateX(-50%);
    width: 100%;
  }
  img {
    object-fit: contain !important;
  }
`

export interface IPlanProps {
  planId: string
  title: string
  description: string
  priceLabel: string
  priceInformation: string
  linkTo?: string
  button: {
    value: string
    theme?: "primary" | "confirm"
    backgroundColor?: string
  }
  badgeText?: string
  highlighted: boolean
  showSam: boolean
}

const Plan = (props: IPlanProps) => {
  const color = props.highlighted ? "#FF4822" : "#B2B4B3"

  return (
    <Wrapper
      outlineColor={color}
      onClick={() => props.linkTo && navigate(props.linkTo)}
    >
      {props.badgeText && <Badge>{props.badgeText}</Badge>}
      <Title color={color}>{props.title}</Title>
      <Description color={color}>{props.description}</Description>
      <Price>{props.priceLabel}</Price>
      <PriceInfo>{props.priceInformation}</PriceInfo>
      {props.button && (
        <Button
          backgroundColor={props.highlighted ? "#FF4822" : "#fff"}
          size="regular"
        >
          {props.button.value}
        </Button>
      )}
      {props.showSam && (
        <Sam>
          <StaticImage
            src="../../../images/characters/sam-head.png"
            alt="Sam"
            placeholder="tracedSVG"
          />
        </Sam>
      )}
    </Wrapper>
  )
}

Plan.defaultProps = {}

export default Plan
