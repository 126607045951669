export const calculateShipping = items => {
  const shippingForItem = item => {
    if (!item.metadata.shopShippingRate) return { value: 0, isNull: true }
    return {
      ...item.metadata.shopShippingRate,
      value:
        item.metadata.shopShippingRate.flatPrice +
        item.metadata.shopShippingRate.pricePerItem * item.quantity,
    }
  }

  const shippingRate = items.reduce(
    (acc, item) => {
      const amount = shippingForItem(item)
      if (amount.value > acc.value) {
        return amount
      }
      return acc
    },
    { value: 0, isNull: true }
  )

  if (shippingRate.isNull) return null

  return {
    amount: shippingRate.value,
    formattedAmount: new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: shippingRate.country.currencyCode,
    }).format(shippingRate.value / 100),
    currencyCode: shippingRate.country.currencyCode,
    locale: shippingRate.country.locale,
  }
}

export default {}
